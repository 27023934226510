<template>
  <div class="package-payment-page">
    <section class="section-checkout-detail">
      <b-container
        class="checkout-detail-content mb-4 px-4 mt-0 mt-md-5 mb-4"
        v-loading.fullscreen.lock="true"
      >
        <div v-if="!loading" class="pro-feature ck-package py-4">
          <div class="row pt-4 text-center mb-4">
            <div class="col-12 text-center d-flex justify-content-center mt-4">
              <div class="wechat-qr py-4">
                <img
                  v-if="status"
                  src="@/assets/img/icon/checked.png"
                  style="width: 70px"
                  alt="Image"
                />
                <img
                  v-else
                  src="@/assets/img/icon/failed.png"
                  style="width: 70px"
                  alt="Image"
                />
                <p v-if="status" class="text-center">
                  {{
                    this.$t(
                      'payment.Your_booking_has_been_confirmed_Check_your_email_for_detials',
                    )
                  }}
                </p>
                <p v-else class="text-center">
                  {{ this.$t('payment.Payment_Failed_Please_try_again') }}
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <b-button href="#/" variant="warning">
                <em class="fa fa-arrow-left mr-2" aria-hidden="true"></em>
                {{ $t('payment.Return_home_page') }}
              </b-button>
            </div>
            <div class="col-6 text-right">
              <b-button href="#/my-order" variant="warning"
                >{{ $t('payment.Go_to_My_order')
                }}<em class="fa fa-arrow-right ml-2" aria-hidden="true"></em
              ></b-button>
            </div>
          </div>
        </div>
        <div v-else>
          <Spinner />
        </div>
      </b-container>
    </section>
  </div>
</template>

<script>
const MAX_CALLED = 40;
import Spinner from '../Layout/spinner/Spinner.vue';
export default {
  name: 'success',
  components: { Spinner },
  data() {
    return {
      text: '',
      status: true,
      timer: 0,
      callCount: 0,
      loading: true,
    };
  },
  created() {
    if (this.$route.params.status === 'true') {
      console.log(this.$route);
      if (this.$route.query.OrderId) {
        // console.log(this.$route.query.OrderId);
        this.checkPayStatus(this.$route.query.OrderId);
        return 
      } else {
        this.loading = false;
        return;
      }
    }
    this.loading = false;
    this.status = false;
  },
  methods: {
    checkPayStatus(id) {
      if (this.callCount >= 40) {
        return;
      }
      this.callCount++;
      clearInterval(this.timer);
      this.$store
        .dispatch('paymentModule/payBack', { id: id })
        .then((data) => {
          console.log(data)
          console.log(data==null)
          if (data == null) {
            console.log(data==null)
            this.timer = setTimeout(() => this.checkPayStatus(id), 5000);
          }
          if (data == 1) {
            this.status = true;
            this.loading = false;
          }
          if (data == 0) {
            this.status = false;
            this.loading = false;
          }
        })
        .catch((e) => {
          this.status = false;
          this.loading = false;
        });
    },
  },
};
</script>
<style type="text/css" scoped>
.package-payment-page {
  background-color: #e0eaeb47;
}
header.header-global {
  background: #fff;
}

.checkout-detail-content {
  background-color: #fff;
}
.pm-head-notice-left p {
  color: #1997f8;
  font-size: 1.2rem;
  padding-bottom: 0;
  margin-bottom: 10px;
}
.pm-head-notice-left img {
  width: 20px;
}
.pm-head-notice-left span,
.order-number,
.amount-payable {
  color: #333333;
}
.pm-head-notice-left span > span {
  color: #f66c4b;
}
.pm-head-notice-right {
  color: #1997f8;
  font-size: 0.9rem;
}
.order-number span {
  color: #1997f8;
  /*font-size: 1rem;*/
}
.amount-payable span {
  color: #f66c4b;
  font-size: 1.1rem;
}
.pm-pattern {
  line-height: 40px;
  color: #333333;
  font-size: 1.3rem;
  padding-left: 7px;
}
hr.new2 {
  border-top: 1px dashed #cccccc;
}
.pm-pattern-choise img {
  width: 35px;
}
.pm-addbank label {
  color: #1997f8;
}
.pm-form-choise {
  border: 1px solid #cccccc54;
}
.pm-submit-note {
  color: #666666e0;
  margin-top: 0;
  font-size: 13px;
}
.wechat-qr {
  width: 500px;
  border: 1px solid #11111114;
}
.wechat-qr img {
  width: 50%;
  margin: auto;
}
.wechat-qr p {
  font-size: 1.2rem;
}
.modal-title {
  font-size: 2rem;
  color: #5f997c;
}
.wechat-qr p {
  padding: 1rem 2rem;
  color: #333333;
}
.other-amount p a {
  color: #f6a604;
}
#triangle-left {
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-right: 8px solid #f6a604;
  border-bottom: 7px solid transparent;
  display: inline-flex;
  line-height: 10px;
}
#triangle-right {
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-left: 8px solid #f6a604;
  border-bottom: 7px solid transparent;
  display: inline-flex;
  line-height: 10px;
}

@media (max-width: 768px) {
  .btn-warning {
    font-size: 12px;
    padding: 0.5rem;
    width: 160px;
  }
}
</style>
